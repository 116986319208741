import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"
import Parallax from "@/components/general/Parallax"
import Heading from "@/components/general/Heading"
import BoredomGraph from "@/components/graphs/BoredomGraph.es"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup.es"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"
import Footer from "@/components/general/Footer.es"
import Motif from "@/components/general/Motif"
import pullquote from "@/images/boredom/pullquote_spanish.svg"
import Share from "@/components/general/Share.es"
import openerText from "@/images/boredom/opener-text_spanish.png"
import openerTextSml from "@/images/boredom/opener-text-sml_spanish.png"

import dci from "@/images/boredom/dci_spanish.png"
import dcb from "@/images/boredom/dcb_spanish.png"
import shareImg from "@/images/og/og_boredom_spanish.jpg"

import SignupPopup from "@/components/general/SignupPopup.es"

const BoredomPage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/es/boredom"
                title="Free Markets Destroy | 
Aprenda cómo los mercados libres destruyen el aburrimiento"
                description="El aburrimiento es un producto de lujo. Durante la mayor parte de la historia humana, no podíamos darnos el lujo de aburrirnos. Pero en los EE. UU., la semana laboral promedio se desplomó de 61 horas en 1870 a menos de 35 horas en la actualidad."
                img={shareImg}
                lang="es"
            />

            <Opener
                pageName="boredom"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            />

            {
                // ======================================= SECTION 1 =======================================
            }

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    { min: 1024, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <div className={"copy--lead"}>
                    <Dropcap img={dcb}>E</Dropcap>l aburrimiento es un bien de lujo. Durante la mayor parte de la historia de la humanidad, no podíamos permitirnos el lujo de aburrirnos. Estábamos demasiado ocupados cazando, buscando comida, luchando, reparando, simplemente sobreviviendo. De hecho, la palabra inglesa "aburrimiento" no existió hasta el siglo XIX, cuando filósofos como Søren Kierkegaard y Friedrich Nietzsche comenzaron a lidiar con el desafío existencial de llenar el tiempo libre que habían creado los mercados libres.

                </div>
            </Sizing>

            <Sizing
                styles={{ float: "left", clear: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            display: "none",
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 3,
                            display: "block",
                            width: 4,
                            marginBottom: 1,
                            marginTop: 4,
                        },
                    },
                ]}
            >
                <Motif movement={0.1} page="boredom" id="motif_1" />
            </Sizing>

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 4,
                            width: 8,
                            marginBottom: 1,
                        },
                    },
                ]}
            >
                <p className={"copy"}>
                    En los Estados Unidos, la semana laboral promedio se ha desplomado de 61 horas en 1870 a menos de 35 horas en la actualidad. Según los CDC, los estadounidenses ahora pasan más de 5 horas por día "entreteniéndose, socializando, participando en actividades de ejercicio y recreación, como voluntarios, tomando clases por interés personal y viajando".
                </p>
                <p className={"copy"}>
                    Los estadounidenses ahora pasan casi un tercio de nuestras horas de vigilia relajándose. Y la razón, en una palabra, son los mercados. Los mercados libres no solo hicieron que nuestro trabajo fuera más productivo, sino que obligaron a los empleadores a competir. Como resultado, las empresas y los jefes comenzaron a ofrecer mejores salarios, jornadas más cortas y, en la era moderna, oportunidades para el trabajo remoto que ahorra tiempo. Desde 1950, el trabajo per cápita global ha disminuido un 17%, de 2227 horas al año a 1855. Durante ese mismo período, los ingresos ajustados por inflación aumentaron un 111%. Eso significa que, en promedio, ganamos el doble mientras trabajamos significativamente menos.
                </p>
                <p className={"copy"}>
                    Así que la próxima vez que se aburra, culpe al libre mercado. Luego, elija un iPhone, un Kindle o una Xbox.
                </p>
            </Sizing>

            {
                // ======================================= GRAPH =======================================
            }

            <div style={{ float: "left", clear: "left" }}>
                <SignupPopup></SignupPopup>
            </div>

            <BoredomGraph />

            {
                // ======================================= SECTION 2 =======================================
            }

            <ColorChangeBG
                from={{ r: 183, g: 131, b: 185 }}
                to={{ r: 233, g: 146, b: 69 }}
            >
                <Spacing space="10%" />
                <Sizing
                    styles={{ float: "left", position: "relative", zIndex: 4 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 10,
                                marginBottom: 2,
                            },
                        },
                    ]}
                >
                    <Heading navy={true} langKey={pageContext.langKey}>
                        VALOR SUBJETIVO EN LA ERA DE LOS SMARTPHONES
                    </Heading>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative", zIndex: 4 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: -3,
                                height: 0,
                                marginBottom: 0,
                                marginTop: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 1,
                                width: 5,
                                marginBottom: 1,
                                marginTop: 1,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: 3,
                                    width: 2,
                                    top: -5,
                                },
                            },
                        ]}
                    >
                        <Motif movement={-0.1} page="boredom" id="motif_3" />
                    </Sizing>
                    <Motif movement={0.1} page="boredom" id="motif_4" />
                </Sizing>

                <Sizing
                    styles={{ float: "left", clear: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 18,
                                marginBottom: 4,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 4,
                                marginBottom: 2,
                                minWidth: "350px",
                            },
                        },
                    ]}
                >
                    <Signup color={"#68C5B8"} />
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 8,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginRight: 4,
                                width: 8,
                                marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <div className={"copy--lead copy--navy"}>
                        <Dropcap img={dci}>E</Dropcap>n un mercado libre, no hay vacas sagradas. En 2004, Blockbuster operó más de 9,000 tiendas, recaudando $ 800 millones en ingresos solo de los cargos por pagos atrasados. Siete años después, la empresa con problemas de liquidez anunció planes para cerrar todas sus tiendas restantes en unos pocos meses. De gigante a quebrado en una década, ese es el poder de los mercados libres.
                    </div>
                    <p className={"copy"}>
                        Para ser específico, ese es el poder de Netflix, Hulu y YouTube. La competencia es el motor que impulsa el progreso en los mercados, y miles de empresas compiten por el derecho a mantenernos entretenidos, informados y felices. Pero hay una trampa, algo que los economistas llaman "valor subjetivo".
                    </p>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -5,
                                    width: 5,
                                    bottom: -1,
                                },
                            },
                        ]}
                    >
                        <Motif movement={-0.1} page="boredom" id="motif_2" />
                    </Sizing>
                </Sizing>
                <Spacing space="0"></Spacing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 0.5,
                                width: 23,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 1,
                                width: 19,
                                paddingBottom: 0.3,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <Image src="boredom_blockbuster" />
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 17,

                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <div className="caption">
                        - De 2004 a 2019, Blockbuster pasó de 9,094 tiendas a solo una.
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 6,
                                width: 8,
                                paddingTop: 2,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <p className={"copy"}>
                        En esencia, el valor subjetivo es simplemente la creencia de que todo el mundo tiene preferencias únicas. El valor subjetivo explica por qué a algunas personas les encanta el jazz, la poesía o la película Die Hard, mientras que otras no las soportan. Los productos, incluidos los programas de televisión, los libros y los juegos, no tienen un valor innato. Su valor depende de las preferencias subjetivas de personas como usted. Y las preferencias cambian con el tiempo. Es por eso que Blockbuster puede valer miles de millones en 2004 y centavos solo unos años después.
                    </p>
                    <p className={"copy"}>
                        En las últimas décadas, a medida que la tecnología facilitó a los creadores y distribuidores de contenido encontrar una audiencia, hemos sido testigos de una explosión en la cantidad y variedad de productos diseñados para llenar nuestro tiempo libre. Con nada más que un iPhone y una conexión a Internet, puede acceder a YouTube, Instagram o TikTok; todo Internet está al alcance de su mano. Puede conectarse con amigos en Discord o aprender francés en DuoLingo. Puedes meditar con Headspace o buscar recetas en Tasty.{" "}
                    </p>
                </Sizing>

                <Spacing space="10%"></Spacing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "block",
                                marginLeft: 2,
                                width: 9,
                                paddingBottom: 0.3,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <img style={{ width: "100%" }} src={pullquote} alt="" />`
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: -2,
                                width: 24,
                                height: 22,
                            },
                        },
                        {
                            min: 1024,

                            val: {
                                marginRight: 0,
                                width: 12.5,
                                marginTop: 1,
                                height: 7,
                                marginLeft: 0,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <Image
                        src="boredom_streamer"
                        style={{
                            position: "absolute",
                            width: "100%",
                            left: 0,
                            bottom: 0,
                        }}
                    ></Image>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 6,
                                width: 8,
                                paddingTop: 2,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <p className={"copy"}>
                        Aproximadamente las tres cuartas partes del aumento del tiempo libre desde 2000 se ha destinado a los juegos. Los juegos ahora ocupan más de 7.5 horas de nuestro tiempo libre cada semana. Lo que alguna vez fue una industria de nicho al servicio de una base de fans pequeña pero dedicada, se ha convertido en un monstruo de $120 mil millones. Y gracias a innovaciones como Twitch, la plataforma de transmisión en vivo adquirida por Amazon por $ 970 millones, miles de jugadores han podido convertir su pasatiempo en una fuente de ingresos mientras compiten y colaboran con otros jugadores de todo el mundo.
                    </p>
                    <p className={"copy"}>
                        En el siglo XIX, el problema era que no teníamos nada que ver con todo nuestro tiempo libre recién descubierto. En 2020, el problema es que tenemos mucho que hacer. En 2019, Netflix lanzó un nuevo título original todos los días. Eso es más contenido nuevo que el de toda la industria de la televisión en conjunto producido en 2005.
                    </p>
                    <p className={"copy"}>
                        Más, mejor, más barato y para todos: ese es el poder de los mercados para mejorarlo todo.
                    </p>
                </Sizing>
            </ColorChangeBG>

            <div style={{ backgroundColor: " rgb(233, 146, 69)" }}>
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginTop: -2,
                                width: 24,
                            },
                        },
                    ]}
                >
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: 20,
                                    maxWidth: "300px",
                                    height: "50px",
                                    marginTop: 2,
                                    paddingBottom: 5,
                                    position: "relative",
                                    display: "block",
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    display: "none",
                                },
                            },
                        ]}
                    >
                        <Share></Share>
                    </Sizing>
                    <Spacing space="0" />
                    <Image
                        src="boredom_hiker"
                        style={{
                            width: "100%",
                        }}
                    />
                </Sizing>
                <Spacing space="0" />
            </div>

            {/* <Spacing space="300vh" /> */}

            <FooterNav next={"racism"} previous={"climate"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default BoredomPage
